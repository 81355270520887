import { api } from '../api'

export default {
  namespaced: true,

  actions: {
    addHouse: (state, params) => {
      return new Promise((resolve, reject) => {
        api.post('house_addition_modelling/', params).then(({data}) => {
          resolve(data)
          return data
        }).catch((error) => {
          reject(error)
          console.log(error)
        });
      })
    }
  }
};