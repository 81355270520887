import { api } from '../api'

export default {
  namespaced: true,

  state: {
    tableData: [],
    countItems: 0
  },

  getters: {
    tableData: state => {
      const addLeadingZero = (num) => {
        return (num < 10) ? '0' + num : num
      }

      const getTime = (time) => {
        const Y = time.getFullYear();
        const M = addLeadingZero(time.getMonth() + 1);
        const D = addLeadingZero(time.getDate());
        const h = addLeadingZero(time.getHours());
        const m = addLeadingZero(time.getMinutes());

        return `${D}.${M}.${Y} ${h}:${m}`
      };

      return state.tableData.map((item) => {
        return {
          update_date: getTime(new Date( Date.parse(item.update_date) )),
          object_name: item.object_name,
          field_name: item.field_name,
          old_value: item.old_value,
          new_value: item.new_value,
          user: item.user,
        };
      });
    },
    countItems: state => state.countItems
  },

  mutations: {
    SET_CHANGE_LOG(state, payload) {
      state.tableData = payload;
    },
    SET_COUNT_ITEMS(state, payload) {
      state.countItems = payload
    }
  },

  actions: {
    getChangeLog: (state, params) => {
      return new Promise((resolve, reject) => {
        api.get(`change_log/`, { params: params }).then(({data}) => {
          resolve(data.results)
          state.commit("SET_CHANGE_LOG", data.results);
          state.commit("SET_COUNT_ITEMS", data.count)
        }).catch((error) => {
          reject(error)
          state.commit("SET_CHANGE_LOG", []);
        });
      })
    }
  },
};