import { api } from '../api'

export default {
  actions: {
    getMethod(store, { url, mutationType, params }) {
      params = params || {};
      return api
        .get(url, {
          headers: { Authorization: `Token ${store.state.accessToken}` },
          params,
        })
        .then((response) => {
          if (mutationType) {
            store.commit(mutationType, response.data);
          }
          return response.data;
        })
        .catch((error) => {
          if (error) {
            return error.response;
          }
        });
    },

    authMethod(store, { url, data, mutationType }) {
      return api
        .post(url, data)
        .then((response) => {
          if (mutationType) {
            store.commit(mutationType, response.data);
          }
          return response;
        })
        .catch((error) => {
          if (error) {
            return error.response;
          }
        });
    },

    postMethod(store, { data }) {
      const _data = data || "";
      return new Promise((resolve, reject) => {
        api.post('/update', _data)
        .then((response) => {
          resolve(response)
          return response;

        })
        .catch((error) => {
          if (error) {
            reject(error)
            return error.response;
          }
        });
        })
    },

    putMethod(store, { url, params, data, mutationType }) {
      const _params = params || "";
      const _data = data || "";
      return api
        .put(url, _data, {
          headers: { Authorization: `Token ${store.state.accessToken}` },
          params: _params,
        })
        .then((response) => {
          if (mutationType) {
            store.commit(mutationType, response.data);
          }
          return response;
        })
        .catch((error) => {
          if (error) {
            return error.response;
          }
        });
    },

    deleteMethod(store, { url, mutationType }) {
      return api
        .delete(url, {
          headers: { Authorization: `Token ${store.state.accessToken}` },
        })
        .then((response) => {
          if (mutationType) {
            store.commit(mutationType, response.data);
          }
          return response;
        })
        .catch((error) => {
          if (error) {
            return error.response;
          }
        });
    },

    patchMethod(store, { url, params, data }) {
      const _params = params || "";
      const _data = data || "";
      return api
        .patch(url, _data, {
          headers: { Authorization: `Token ${store.state.accessToken}` },
          _params,
        })
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error) {
            return error.response;
          }
        });
    },
  },
};
