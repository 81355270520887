import { api } from '../api'

export default {
  namespaced: true,

  state: {
    modelChange: []
  },

  getters: {
    modelChange: (state) => state.modelChange
  },

  mutations: {
    SET_MODEL_CHANGE(state, payload) {
      state.modelChange = payload;
    }
  },

  actions: {
    getModelChange: ({commit}) => {
      return api.get('get_model_changes').then(({data}) => {
        commit("SET_MODEL_CHANGE", data);
      }).catch(() => {
        commit("SET_MODEL_CHANGE", []);
      });
    },
  },
};