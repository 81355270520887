import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const ifAuthenticated = (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    next()
    return
  }
  next('/')
}

const routes = [
  {
    path: '/',
    name: 'Base',
    component: () => import(/* webpackChunkName: "base" */ "../views/Index"),
    children: [
      {
        path: '',
        name: 'Main',
        component: () => import(/* webpackChunkName: "Main" */ '../views/modules/Home/Index'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import(/* webpackChunkName: "Search" */ '../views/modules/Search/Index'),
        children: [
          {
            path: '',
            name: 'Search',
            component: () => import(/* webpackChunkName: "DefaultSearch" */ '../views/modules/Search/DefaultSearch/Index'),
          },
          {
            path: 'advanced-search',
            name: 'advanced-search',
            component: () => import(/* webpackChunkName: "AdvancedSearch" */ '../views/modules/Search/AdvancedSearch/Index'),
          }
        ]
      },
      {
        path: 'updates',
        name: 'Updates',
        component: () => import(/* webpackChunkName: "Updates" */ '../views/modules/Updates/Index')
      },
      {
        path: 'statistics',
        name: 'Statistics',
        component: () => import(/* webpackChunkName: "Statistics" */ '../views/modules/Statistics/Index')
      },
      {
        path: 'model-changes',
        name: 'ModelChanges',
        component: () => import(/* webpackChunkName: "ModelChanges" */ '../views/modules/ModelChanges/Index')
      },
      {
        path: 'change-log',
        name: 'ChangeLog',
        component: () => import(/* webpackChunkName: "ChangeLog" */ '../views/modules/ChangeLog/Index')
      },
      {
        path: 'objects',
        name: 'Objects',
        component: () => import(/* webpackChunkName: "Objects" */ '../views/modules/Objects/Index')
      },
      {
        path: "login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "../views/modules/Auth/Login"),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
