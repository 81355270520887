import axios from 'axios'
import router from '../router/index'
import store from '../store/index'
import { baseUrl } from '../config'

const api = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  },
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization =  token ? `Bearer ${token}` : '';
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          store.dispatch("auth/logout").then(() => {
            return router.push({ name: 'Login' });
          });
        }
      }
    }
    return Promise.reject(error);
  });

export {api};