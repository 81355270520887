import { api } from '../api'

export default {
  namespaced: true,

  state: {
    tableData: [],
    lastDate: ''
  },

  getters: {
    tableData: state => state.tableData,
    lastDate: state => {
      const newDate = state.lastDate.split('-')
      return `${newDate[2]}.${newDate[1]}.${newDate[0]}`
    }
  },

  mutations: {
    SET_TABLE_DATA(state, payload) {
      state.tableData = payload;
    },
    SET_DATE(state, payload) {
      state.lastDate = payload;
    },
  },

  actions: {
    getStatistics: ({commit}) => {
      return new Promise((resolve, reject) => {
        api.get(`gar_statistics`).then(({data}) => {
          resolve(data);
          commit("SET_TABLE_DATA", data.result);
          commit("SET_DATE", data.last_date);
        }).catch((error) => {
          reject(error)
          commit("SET_TABLE_DATA", []);
          commit("SET_DATE", '');
        });
      })
    }
  },
};