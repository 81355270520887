import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import search from './modules/search'
import advancedSearch from './modules/advanced-search'
import attachment from './modules/attachment'
import modelChange from './modules/model-change'
import update from './modules/update'
import changeLog from './modules/change-log'
import addHouse from './modules/add-house'
import statistics from './modules/statistics'
import changeHouseParams from './modules/change-house-params'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    search,
    advancedSearch,
    attachment,
    modelChange,
    update,
    changeLog,
    addHouse,
    statistics,
    changeHouseParams
  }
})
