import { api } from '../api'

export default {
  namespaced: true,

  state: {
    items: [],
    object: '',
  },

  getters: {
    items: state => state.items,
    searchList: (state) => {
      return state.items.map((item) => {
        return {
          value: item.full_name
        };
      });
    },
    object: state => state.object
  },

  mutations: {
    SET_SEARCH_LIST(state, payload) {
      state.items = payload;
    },
    SET_OBJECT(state, payload) {
      state.object = payload;
    },
  },

  actions: {
    getSearchList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`address_object/?name=${params}`).then(({data}) => {
          resolve(data)
          commit("SET_SEARCH_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_SEARCH_LIST", []);
        });
      })
    },
    getObject: ({commit}, params) => {
      return api.get(`address_object/${params}/`).then(({data}) => {
        debugger
        commit("SET_OBJECT", data);
        console.log(data);
      }).catch(() => {
        commit("SET_OBJECT", {});
      });
    }
  },
};