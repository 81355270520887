import { api } from '../api'
import router from '../../router'

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('token') || '',
    refresh: '',
    status: '',
    username: localStorage.getItem('username') || '',
  },
  mutations: {
    AUTH_LOGOUT: (state) =>{
      state.token = ''
      state.refresh = ''
      localStorage.setItem('token', '')
      localStorage.setItem('username', '')
    },
    AUTH_REQUEST: (state) => {
      state.status = 'loading'
    },
    AUTH_SUCCESS: (state, payload) => {
      state.status = 'success'
      state.username = payload.username
      localStorage.setItem('token', payload.access)
      localStorage.setItem('username', payload.username)
      state.token = payload.access
      state.refresh = payload.refresh
    },
    AUTH_ERROR: (state) => {
      state.status = 'error'
    },
  },
  actions: {
    login: (state, {username, password}) => {
      return new Promise((resolve, reject) => {
        api.post('token/', {username, password}).then((response) => {
          state.commit('AUTH_SUCCESS', response.data)
          resolve(response);
        })
          .catch((error) => {
            state.commit('AUTH_ERROR', error)
            localStorage.removeItem('token')
            reject(error);
          });
      });
    },
    logout:(state)=>{
      state.commit('AUTH_LOGOUT')
    },
    registration: (state, user) => {
      return new Promise((resolve, reject) => {
        api.post('token/', user).then((response) => {
          state.commit('AUTH_SUCCESS', response.data)
          resolve(response);
        })
          .catch((error) => {
            state.commit('AUTH_ERROR', error)
            localStorage.removeItem('token')
            reject(error);
          });
      });
    }
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    username: state => state.username
  }
}