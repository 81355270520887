import { api } from '../api'

export default {
  namespaced: true,

  state: {
    items: [],
  },

  getters: {
    items: state => state.items,
  },

  mutations: {
    SET_UPDATE_LIST(state, payload) {
      state.items = payload;
    },
  },

  actions: {
    sendUpdate({commit}, data){
      return new Promise((resolve, reject) => {
        console.log(commit)
        api.get(`update_delta/${data.id}/`).then(({data}) => {
          resolve(data)
        }).catch((error) => {
          reject(error)
        });
      })
    },
    getListUpdate: ({commit}) => {
      return new Promise((resolve, reject) => {
        api.get(`get_deltas/`).then(({data}) => {
          resolve(data)
          commit("SET_UPDATE_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_UPDATE_LIST", []);
        });
      })
    }
  },
};