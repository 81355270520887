import { api } from '../api'

export default {
  namespaced: true,

  state: {
    district: [],
    settlement: [],
    city: [],
    locality: [],
    planningStructure: [],
    roadElement: [],
    buildingNumber: [],
    premisesNumber: [],
    roomNumber: [],
    postalCode: '',
    uniqueNumber: '',
    cadastralNumber: '',
    object: ''
  },

  getters: {
    district: state => state.district,
    settlement: state => state.settlement,
    city: state => state.city,
    locality: state => state.locality,
    planningStructure: state => state.planningStructure,
    roadElement: state => state.roadElement,
    buildingNumber: state => state.buildingNumber,
    premisesNumber: state => state.premisesNumber,
    roomNumber: state => state.roomNumber,
    postalCode: state => state.postalCode,
    uniqueNumber: state => state.uniqueNumber,
    cadastralNumber: state => state.cadastralNumber,
    object: state => state.object,
  },

  mutations: {
    SET_DISTRICT_LIST(state, payload) {
      state.district = payload;
    },
    SET_SETTLEMENT_LIST(state, payload) {
      state.settlement = payload;
    },
    SET_CITY_LIST(state, payload) {
      state.city = payload;
    },
    SET_LOCALITY_LIST(state, payload) {
      state.locality = payload;
    },
    SET_PLANNING_STRUCTURE_LIST(state, payload) {
      state.planningStructure = payload;
    },
    SET_ROAD_ELEMENT_LIST(state, payload) {
      state.roadElement = payload;
    },
    SET_BUILDING_NUMBER_LIST(state, payload) {
      state.buildingNumber = payload;
    },
    SET_PREMISES_NUMBER_LIST(state, payload) {
      state.premisesNumber = payload;
    },
    SET_ROOM_NUMBER_LIST(state, payload) {
      state.roomNumber = payload;
    },
    SET_OBJECT(state, payload) {
      state.object = payload;
    },
  },

  actions: {
    getDistrictList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get('filter/mun_districts/', {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_DISTRICT_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_DISTRICT_LIST", []);
        });
      })
    },
    getSettlementList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/settlements/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_SETTLEMENT_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_SETTLEMENT_LIST", []);
        });
      })
    },
    getCityList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/cities/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_CITY_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_CITY_LIST", []);
        });
      })
    },
    getLocalityList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/localities/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_LOCALITY_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_LOCALITY_LIST", []);
        });
      })
    },
    getPlanningStructureList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/plan_structures/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_PLANNING_STRUCTURE_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_PLANNING_STRUCTURE_LIST", []);
        });
      })
    },
    getRoadElementList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/streets/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_ROAD_ELEMENT_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_ROAD_ELEMENT_LIST", []);
        });
      })
    },
    getBuildingNumberList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/houses/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_BUILDING_NUMBER_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_BUILDING_NUMBER_LIST", []);
        });
      })
    },
    getPremisesNumberList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/apartments/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_PREMISES_NUMBER_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_PREMISES_NUMBER_LIST", []);
        });
      })
    },
    getRoomNumberList: ({commit}, params) => {
      return new Promise((resolve, reject) => {
        api.get(`filter/rooms/`, {params: params}).then(({data}) => {
          resolve(data)
          commit("SET_ROOM_NUMBER_LIST", data);
          console.log(data);
        }).catch((error) => {
          reject(error)
          commit("SET_ROOM_NUMBER_LIST", []);
        });
      })
    },
    //--------------------------------
    getObject: ({commit}, params) => {
      return api.get('advanced_search', {params: params}).then(({data}) => {
        commit("SET_OBJECT", data);
        console.log(data);
      }).catch(() => {
        commit("SET_OBJECT", {});
      });
    },
    getObjectByCadNum: ({commit}, params) => {
      return api.get('filter/find_by_cadnum/', {params: params}).then(({data}) => {
        commit("SET_OBJECT", data);
        console.log(data);
      }).catch(() => {
        commit("SET_OBJECT", {});
      });
    }
  },
};